module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Vishandel Heinen & Zn.","short_name":"Vishandel Heinen & Zn.","start_url":"/","background_color":"#00a7e0","theme_color":"#00a7e0","display":"standalone","icon":"src/img/favicon.png","crossOrigin":"use-credentials"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NLZB5CR","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Qwigley","Montserrat:200,400,700"]}},
    }]
